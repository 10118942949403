export default class Parcel {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.parcelNo = data ? data.parcelNo : '';
    this.description = data ? data.description : '';
    this.packTypeCode = data ? data.packTypeCode : '';
    this.length = data ? data.length : '';
    this.width = data ? data.width : '';
    this.height = data ? data.height : '';
    this.weight = data ? data.weight : '';
    this.volume = data ? data.volume : '';
    this.packagingReturn = data ? data.packagingReturn : false;
  }
}
