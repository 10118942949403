export const deliveryConditionsList = [
  {
    id: '1',
    name: 'Awiazacja',
  },
  {
    id: '2',
    name: 'Samochód z windą',
  },
  {
    id: '3',
    name: 'Telefon przed dostawą',
  },
  {
    id: '4',
    name: 'Paleciak',
  },
  {
    id: '5',
    name: 'Wniesienie',
  },
  {
    id: '6',
    name: 'Towar delikatny',
  },
  {
    id: '7',
    name: 'Zwrot dokumentów',
  },
  {
    id: '8',
    name: 'Zabranie zbędnych opakowań',
  },
  {
    id: '10',
    name: 'Wniesienie do wskazanego pomieszczenia',
  },
  {
    id: '11',
    name: 'Drobny montaż(Kierowca)',
  },
];
