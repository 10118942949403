import Person from './Person';
import Parcel from './Parcel';

export default class Order {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.pickupComments = data ? data.pickupComments : '';
    this.deliveryConditions = data ? data.deliveryConditions : '';
    this.customsClearance = data ? data.customsClearance : false;
    this.sender = new Person();
    this.recipient = new Person();
    this.parcel = new Parcel();
  }
}
