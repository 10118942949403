<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t('courierSet.orderData').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="pickupComments"
              :title="$t('courierSet.pickupComments')"
              @input="update"
            />
          </v-col>
          <v-col>
            <select-field
              v-model="deliveryConditions"
              :title="$t('courierSet.deliveryConditions')"
              :filed-items="deliveryConditionsList"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <check-field
              v-model="customsClearance"
              :label="$t('courierSet.customsClearance')"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import CheckField from '@/components/inputs/CheckField.vue';
import SelectField from '@/components/inputs/SelectField.vue';

import { deliveryConditionsList } from '@/constants/Speditors/Nolimit/DeliveryConditionsList';

export default {
  components: {
    TextField,
    SelectField,
    CheckField,
  },
  props: {
    confData: { type: Object, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },

  data: () => ({
    valid: null,

    confSet: null,
    tenantId: null,
    id: null,
    deliveryConditions: null,
    customsClearance: null,
    pickupComments: null,
    deliveryConditionsList,
  }),
  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.additionalPayments = {
        parameters: {
          clientEntityState: this.isNew ? 4 : 3,
          id: this.id,
          tenantId: this.tenantId,
          deliveryConditions: this.deliveryConditions,
          customsClearance: this.customsClearance ? this.customsClearance : false,
          pickupComments: this.pickupComments,
        },
      };
      this.$emit('input', this.additionalPayments);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.orderDetails = this.confSet?.nolimitDefaultValues?.order;

      this.id = this.orderDetails?.id;
      this.tenantId = this.orderDetails?.tenantId;

      this.customsClearance = this.orderDetails?.customsClearance
        ? this.orderDetails?.customsClearance
        : null;
      this.pickupComments = this.orderDetails?.pickupComments
        ? this.orderDetails?.pickupComments
        : null;
      this.deliveryConditions = this.orderDetails?.deliveryConditions
        ? this.orderDetails?.deliveryConditions
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
.v-select {
  padding: 0px 10px 0px 10px;
}
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
