import Order from './Order';

export default class NolimitDefaultValues {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.order = new Order();
  }
}
