<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t('courierSet.parcel').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <select-field
              v-model="packTypeCode"
              :title="$t('courierSet.packTypeCode')"
              rules="required"
              :filed-items="packageList"
              @input="update"
            />
          </v-col>
          <v-col>
            <text-field
              v-model="description"
              :title="$t('courierSet.parcelDescription')"
              rules="required"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';

export default {
  components: {
    TextField,
    SelectField,
  },
  props: {
    confData: { type: Object, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    confSet: null,
    parcel: null,
    id: null,
    tenantId: null,
    valid: null,
    parcelNo: null,
    description: null,
    packTypeCode: null,
    length: null,
    width: null,
    height: null,
    weight: null,
    packageList: [
      {
        id: 'KAR',
        name: 'Karton',
      },
      {
        id: 'OVK',
        name: 'Karton oversize',
      },
      {
        id: 'PC1',
        name: 'Półpaleta',
      },
      {
        id: 'EUR',
        name: 'Paleta EUR',
      },
      {
        id: 'OVP',
        name: 'Paleta oversize',
      },
      {
        id: 'PAL',
        name: 'Paleta przemysłowa',
      },
      {
        id: 'BOX',
        name: 'Box',
      },
      {
        id: 'KOP',
        name: 'Koperta',
      },
      {
        id: 'INN',
        name: 'Inne',
      },
    ],
  }),

  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.parcel = {
        parameters: {
          id: this.id,
          tenantId: this.tenantId,
          clientEntityState: this.isNew ? 4 : 3,

          parcelNo: this.parcelNo,
          description: this.description,
          packTypeCode: this.packTypeCode,
          length: this.length ? parseFloat(this.length) : 0,
          width: this.width ? parseFloat(this.width) : 0,
          height: this.height ? parseFloat(this.height) : 0,
          weight: this.weight ? parseFloat(this.height) : 0,
        },
      };
      this.$emit('input', this.parcel);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.parcel = this.confSet?.nolimitDefaultValues?.order?.parcel;
      this.id = this.parcel?.id ? this.parcel?.id : null;
      this.tenantId = this.parcel?.tenantId ? this.parcel?.tenantId : null;
      this.parcelNo = this.parcel?.parcelNo ? this.parcel?.parcelNo : null;
      this.description = this.parcel?.description ? this.parcel?.description : null;
      this.packTypeCode = this.parcel?.packTypeCode ? this.parcel?.packTypeCode : null;
      this.length = this.parcel?.length ? this.parcel?.length : null;
      this.width = this.parcel?.width ? this.parcel?.width : null;
      this.height = this.parcel?.height ? this.parcel?.height : null;
      this.weight = this.parcel?.weight ? this.parcel?.weight : null;
    },
  },
};
</script>

<style scoped lang="scss">
.v-select {
  padding: 0px 10px 0px 10px;
}
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
