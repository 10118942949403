export default class Person {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.name = data ? data.name : '';
    this.address = data ? data.address : '';
    this.city = data ? data.city : '';
    this.country = data ? data.country : '';
    this.postalCode = data ? data.postalCode : '';
    this.phoneNo = data ? data.phoneNo : '';
    this.email = data ? data.email : '';
    this.phoneNo = data ? data.symbol : '';
  }
}
